import React from "react"

import AnchorLink from "../anchorLink"
import Button from "../button"

function Community() {
    return (
        <section className="py-12 text-center max-w-screen-lg mx-auto">
            <h2 className="text-3xl sm:text-5xl lg:text-6xl leading-none font-bold text-gray-900 mb-8">Join the community</h2>
            <p className="text-lg sm:text-2xl sm:leading-10 font-medium mb-10 sm:mb-11">
                Follow @uxchoice on Instagram for more design tips, resources, and
                inspiration. More than 140k designers already follow us.
            </p>
            <AnchorLink external isButton to="https://instagram.com/uxchoice/">
                Join now
            </AnchorLink>
        </section>
    )
}

function Newsletter() {
    return (
        <section className="py-12 text-center max-w-screen-lg mx-auto">
            <h2 className="text-3xl sm:text-5xl lg:text-6xl leading-none font-bold text-gray-900 mb-8">Weekly newsletter</h2>
            <p className="text-lg sm:text-2xl sm:leading-10 font-medium mb-10 sm:mb-11">
                Get best UI, UX and product design links, delivered to your inbox every week. No spam, unsubscribe anytime.
            </p>
            <div id="revue-embed" class="text-left max-w-screen-sm mx-auto">
                <form action="https://www.getrevue.co/profile/uxchoice/add_subscriber" method="post" id="revue-form" name="revue-form" target="_blank">
                    <div class="md:flex md:items-center mb-6">
                        <div class="md:w-2/3 mr-4">
                            <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded-full w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" placeholder="Your email address..." type="email" name="member[email]" id="member_email" />
                        </div>
                        <div class="md:w-1/3">
                            <input type="submit" value="Subscribe" name="member[subscribe]" id="member_submit" class="btn w-full text-center justify-center" />
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

const menuItems = [
    {
        label: "About us",
        link: "/",
    },
    {
        label: "Resources",
        link: "/",
    },
    {
        label: "Privacy",
        link: "/",
    },
    {
        label: "Terms",
        link: "/",
    },
    {
        label: "Contact us",
        link: "/",
    },
]

function Footer() {
    return (
        <footer className="bg-gray-50">
            <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
                <Community />
                <Newsletter />
                <div className="py-17 text-center max-w-screen-sm mx-auto">
                    {/* <ul className="flex flex-wrap gap-x-8 gap-y-2 mb-7 py-6 border-b border-gray-200 justify-center">
                        {menuItems.map(menuItem => (
                            <li key={menuItem.label}>
                                <AnchorLink
                                    to={menuItem.link}
                                    className="hover:text-gray-600 transition-colors duration-200"
                                >
                                    {menuItem.label}
                                </AnchorLink>
                            </li>
                        ))}
                    </ul> */}
                    <p className="text-xs pb-4">
                        Copyright &copy; {new Date().getFullYear()} UX Choice pvt ltd. All
                        rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
