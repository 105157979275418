import React from "react"
import { Link } from "gatsby"

const AnchorLink = ({ external, isButton, to, children, className, ...otherProps }) => {
  if (external) {
    return (
      <a
        href={to}
        target="_blank"
        rel="nofollow noopener noreferrer"
        className={`${isButton ? "btn" : ''} ${className}`}
      >
        {children}
      </a>
    )
  }

  return (
    <Link to={to} {...otherProps} className={`${isButton ? "btn" : ''} ${className}`}>
      {children}
    </Link>
  )
}

export default AnchorLink
