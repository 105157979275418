import * as React from "react"
import { Helmet } from "react-helmet"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ location, children }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:wght@700&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="text-gray-500 antialiased bg-white js-focus-visible">
        <div className="overflow-hidden">
          <Header />
          <div>{children}</div>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Layout
