import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import clsx from "clsx"

import Logo from "../../assets/logos/logo-uxc.svg"
import AnchorLink from "../anchorLink"

const navLinks = [
  {
    label: "Top Resources",
    url: "/links",
  },
  {
    label: "Books",
    url: "/resources/books",
  },
  {
    label: "Article",
    url: "/resources/articles",
  },
  {
    label: "Videos",
    url: "/resources/videos",
  },
//   {
//     label: "Quotes",
//     url: "/resources/quotes",
//   },
  {
    label: "Newsletter",
    url: "/newsletter",
  },
]

function MobileNav() {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const body = document.body
    if (open) {
      body.classList.add("overflow-hidden")
    } else {
      body.classList.remove("overflow-hidden")
    }
  }, [open])

  const toggleMenu = () => {
    setOpen(open => !open)
  }

  const barClasses =
    "block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"

  return (
    <>
      <button
        type="button"
        className="text-gray-500 w-10 h-10 relative focus:outline-none bg-white z-20"
        onClick={toggleMenu}
      >
        <span className="sr-only">{open ? "Close" : "Open"} main menu</span>
        <div className="block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2">
          <span
            aria-hidden="true"
            className={clsx(barClasses, {
              "rotate-45": open,
              " -translate-y-1.5": !open,
            })}
          ></span>
          <span
            aria-hidden="true"
            className={clsx(barClasses, { "opacity-0": open })}
          ></span>
          <span
            aria-hidden="true"
            className={clsx(barClasses, {
              "-rotate-45": open,
              " translate-y-1.5": !open,
            })}
          ></span>
        </div>
      </button>
      {open ? (
        <div className="bg-white fixed top-0 left-0 bottom-0 right-0 z-10 p-20">
          <ul className="space-y-10">
            {navLinks.map(navLink => (
              <li key={navLink.label}>
                <AnchorLink
                  className="text-3xl font-semibold hover:text-gray-600 transition-colors duration-200 py-2"
                  to={navLink.url}
                  activeClassName="text-gray-800"
                >
                  {navLink.label}
                </AnchorLink>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </>
  )
}

function DesktopNav() {
  return (
    <ul className="flex gap-6">
      {navLinks.map(navLink => (
        <li key={navLink.label}>
          <AnchorLink
            className="font-semibold hover:text-gray-600 transition-colors duration-200 py-2"
            to={navLink.url}
            activeClassName="text-gray-800"
          >
            {navLink.label}
          </AnchorLink>
        </li>
      ))}
    </ul>
  )
}

function Navigation() {
  return (
    <nav className="relative">
      <div className="md:hidden">
        <MobileNav />
      </div>
      <div className="hidden md:block">
        <DesktopNav />
      </div>
    </nav>
  )
}

function Header() {
  return (
    <header className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
      <div className="flex items-center justify-between py-4">
        <div>
          <Link className="header-link-home" to="/">
            <Logo aria-label="UX Choice" />
          </Link>
        </div>
        <div>
          <Navigation></Navigation>
        </div>
      </div>
    </header>
  )
}

export default Header
